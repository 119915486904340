import * as React from "react";
import { observer } from "mobx-react";
import { withStores } from "stores";
import {
  LineOfBusinessDomain,
  OneKeyBanner as SharedUIOneKeyBanner,
  PageLocation,
} from "@shared-ui/retail-loyalty-one-key-banner";
import { OneKeyBannerProps } from "./typings";
import { ProductType, ProductTypeProvider } from "@shared-ui/retail-product-type-providers";

const lineOfBusinessToProductTypeMap: Record<LineOfBusinessDomain, ProductType> = {
  [LineOfBusinessDomain.ACTIVITIES]: ProductType.Activities,
  [LineOfBusinessDomain.CAR]: ProductType.Cars,
  [LineOfBusinessDomain.CRUISES]: ProductType.Cruise,
  [LineOfBusinessDomain.FLIGHT]: ProductType.Unclassified,
  [LineOfBusinessDomain.GROUNDTRANSFERS]: ProductType.GroundTransportation,
  [LineOfBusinessDomain.LODGING]: ProductType.Lodging,
  [LineOfBusinessDomain.UNCLASSIFIED]: ProductType.Unclassified,
  [LineOfBusinessDomain.RAILS]: ProductType.Unclassified,
  [LineOfBusinessDomain.PACKAGES]: ProductType.Unclassified,
};

export const OneKeyBanner = withStores("context")(
  observer((props: OneKeyBannerProps) => {
    const { templateComponent } = props;

    /* istanbul ignore if */
    if (!templateComponent) {
      return null;
    }

    const { metadata } = templateComponent;
    const { pageLocation, lineOfBusiness, useLoyaltyCurrency, showBorder } = templateComponent.config;
    const { id } = metadata;

    if (!pageLocation || !lineOfBusiness) {
      return null;
    }

    const resolvedLineOfBusiness =
      LineOfBusinessDomain[lineOfBusiness as LineOfBusinessDomain] ?? ProductType.Unclassified;
    const productType = lineOfBusinessToProductTypeMap[resolvedLineOfBusiness];

    const oneKeyBannerInput = {
      useLoyaltyCurrency,
      // @ts-ignore
      pageLocation: PageLocation[pageLocation],
      lineOfBusiness: resolvedLineOfBusiness,
      packageType: null,
      packageLOB: null,
    };

    return (
      <div data-testid={id} id={id}>
        <ProductTypeProvider productType={productType}>
          <SharedUIOneKeyBanner inputs={oneKeyBannerInput} showBorder={showBorder} />
        </ProductTypeProvider>
      </div>
    );
  })
);

export default OneKeyBanner;
